export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about": [5],
		"/about/customer-stories": [6],
		"/about/for-locomotives": [7],
		"/about/history": [8],
		"/about/how-zmax-works": [9],
		"/about/specials-and-events": [10],
		"/about/testing": [11],
		"/about/videos": [12],
		"/contact-us": [13],
		"/faqs": [14],
		"/find-a-store": [15],
		"/(forms)/form-giveaway": [4],
		"/(forms)/form": [3],
		"/giveaway": [16]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';